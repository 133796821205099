/* ==========================================================================
   Nord skin
   ========================================================================== */
@import "nordtheme.scss";



/* Colors */
$background-color: $nord0 !default;
$text-color: $nord9 !default;
$primary-color: $nord9 !default;
$border-color: mix(#fff, $background-color, 20%) !default;
$code-background-color: mix(#000, $background-color, 15%) !default;
$code-background-color-dark: mix(#000, $background-color, 20%) !default;
$form-background-color: mix(#000, $background-color, 15%) !default;
$footer-background-color: mix(#000, $background-color, 30%) !default;
///$link-color: $nord14 !default;
$link-color: mix(#fff, $text-color, 25%) !default;

$link-color-hover: mix(#fff, $link-color, 25%) !default;
$link-color-visited: mix(#000, $link-color, 25%) !default;
$masthead-link-color: $nord9 !default;
$masthead-link-color-hover: mix(#fff, $link-color, 20%) !default;
$navicon-link-color-hover: mix(#fff, $link-color, 20%) !default;

.author__urls.social-icons i,
.author__urls.social-icons .svg-inline--fa,
.page__footer-follow .social-icons i,
.page__footer-follow .social-icons .svg-inline--fa  {
  color: inherit;
}

.ais-search-box .ais-search-box--input {
  background-color: $form-background-color;
}


div.justify {
  text-align: justify;
  text-justify: inter-word;
}
